import { Box, TextField } from '@material-ui/core'
import { useSearchLicenseDrafts } from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { Multiline } from 'app/layout/multiline'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { EditLicenseButton } from 'app/license/edit-license-button'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { racemanagerLicensesTitle } from 'app/pages/admin/licenses/approved-licenses'
import { EmergencyDetailsButton } from 'app/pages/admin/licenses/emergency-button'
import { LicenseAttachmentsButton } from 'app/pages/admin/licenses/license-attachments-button'
import { UserEventsButton } from 'app/pages/admin/users/user-events'
import { ImpersonateButton } from 'app/pages/admin/users/user-management'
import { DownloadLicenseButton } from 'app/pages/licenses/download-license'
import { AdminContext, useAdminContext } from 'app/themes/admin-context'
import { routes } from 'shared/config/routes'
import { categoryCommonName, categoryOfAssociationByLicense } from 'shared/data/categories-service'
import { displayIssuedNumber } from 'shared/data/licenses-service'
import { sectionNameWithIdById } from 'shared/data/sections'
import { LicenseWithContext, UserQuery } from 'shared/db/db'
import { licenseTypeName } from 'shared/db/license-type'
import { t, todoT } from 'shared/i18n/current'
import { associationNameWithDefault } from 'shared/models/associations'
import {
  hasMyLapsTransponder,
  hasRfTransponder,
  myLapsTransponderMakes,
  myLapsTransponderNumbers,
  rfTransponderNumbers,
} from 'shared/models/transponder'
import { truthy } from 'shared/utils/array'
import {
  formatDateDe,
  formatDateWithSecondsSpace,
  parseDate,
  parseISO,
  pFormatDateDe,
} from 'shared/utils/date'
import { RoutedButton } from 'utils/buttons/routed-button'
import { useSearchQuery } from 'utils/router'

interface LicenseDraftsProps {
  admin: UserQuery
}

export function LicenseDrafts({ admin }: LicenseDraftsProps) {
  const search = useSearchQuery()
  const { data, loading, error, loadingOrError } = useSearchLicenseDrafts(search.q, true)
  const adminContext = useAdminContext()

  const licenses = data.filter((row) =>
    row.license.draft.category.associations.some((association) =>
      adminContext.matchesAssociation(association)
    )
  )

  const title = racemanagerLicensesTitle(t().routes.licenseDrafts, licenses)
  useRacemanagerTitle(title)

  const exportOnly = true

  return (
    <TableBox
      title={title}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: [
            { value: t().licenseStatus },
            { value: t().lastName },
            { value: t().firstName },
            { value: t().street, exportOnly },
            { value: t().zip, exportOnly },
            { value: t().place, exportOnly },
            { value: t().country, exportOnly },
            { value: t().email, exportOnly },
            { value: t().phone, exportOnly },
            { value: t().birthdate, exportOnly },
            { value: t().birthdateDe, exportOnly },
            { value: t().afmSection.label, exportOnly },
            { value: t().fmsSection.label, exportOnly },
            { value: t().newSamMember.labelAlternative, exportOnly },
            { value: t().samMemberNumber.label, exportOnly },
            { value: t().samSection.label, exportOnly },
            { value: t().parentsInfo, exportOnly },
            { value: t().lastYearCategories, exportOnly },
            { value: t().lastYearLicenses, exportOnly },
            { value: t().lastYearRanks, exportOnly },
            { value: t().lastYearStartNumbers, exportOnly },
            { value: t().hasTransponder.labelAlternative, exportOnly },
            { value: t().transponderMake.labelAlternative, exportOnly },
            { value: t().transponderNumbers.label, exportOnly },
            { value: t().hasTransponderRf.labelAlternative, exportOnly },
            { value: t().transponderNumbersRf.label, exportOnly },
            { value: t().association, maxWidth: 100 },
            { value: t().category, maxWidth: 300 },
            { value: t().licenseType, exportOnly: true },
            { value: t().preferredNumber.labelAlternative, align: 'right' as const },
            { value: t().remarks, maxWidth: 200 },
            { value: t().processedAt, exportOnly },
            actions(),
          ].filter(truthy),
          // eslint-disable-next-line react/display-name
          contents: licenses.map((licenseWithContext) => () => {
            const license = licenseWithContext.license
            const birthdate = license.documents?.personalData?.birthdate
            const parentsInfo = license.documents?.personalData?.parentsInfo
            const processedAt = license.draft.categoryDetails?.processedAt
            return [
              license.type === 'approved'
                ? todoT('Bestätigt')
                : todoT(`Neu (${pFormatDateDe(processedAt) || '--'})`),
              license.documents?.personalData?.lastName || '',
              license.documents?.personalData?.firstName || '',
              license.documents?.personalData?.street || '',
              license.documents?.personalData?.zip || '',
              license.documents?.personalData?.place || '',
              license.documents?.personalData?.country || '',
              license.documents?.personalData?.email || '',
              license.documents?.personalData?.phone || '',
              birthdate || '',
              formatDateDe(parseDate(birthdate)),
              sectionNameWithIdById(license.documents.personalData?.afmSection),
              sectionNameWithIdById(license.documents.personalData?.fmsSection),
              license.documents?.personalData?.newSamMember ? 'x' : '',
              license.documents?.personalData?.samMemberNumber || '-',
              sectionNameWithIdById(license.documents.personalData?.samSection),
              typeof parentsInfo === 'string' ? parentsInfo : '',
              license.documents?.lastYear?.lastYearCategories || '',
              license.documents?.lastYear?.lastYearLicenses || '',
              license.documents?.lastYear?.lastYearRanks || '',
              license.documents?.lastYear?.lastYearStartNumbers || '',
              hasMyLapsTransponder(license.documents?.transponder) ? 'x' : '',
              myLapsTransponderMakes(license.documents?.transponder),
              myLapsTransponderNumbers(license.documents?.transponder),
              hasRfTransponder(license.documents?.transponder) ? 'x' : '',
              rfTransponderNumbers(license.documents?.transponder),
              associationNameWithDefault(licenseWithContext.license.association),
              categoryCommonName(license.draft.category),
              licenseTypeName(license.draft.categoryDetails?.licenseType),
              license.type === 'draft'
                ? license.draft.categoryDetails?.preferredNumber || ''
                : categoryOfAssociationByLicense(license.approved).numberChoice
                ? displayIssuedNumber(license.approved)
                : '-',
              <Multiline key="remarks">{license.draft.summary?.remarks || ''}</Multiline>,
              processedAt ? formatDateWithSecondsSpace(parseISO(processedAt)) : '',
              <>
                <FinancialDialog admin={admin} user={{ uid: license.userId }} />
                {allowedToEditLicenseDraft(licenseWithContext, adminContext) && (
                  <EditLicenseButton licenseWithContext={licenseWithContext} admin={admin} />
                )}
                <LicenseAttachmentsButton
                  user={{ uid: license.userId }}
                  documents={license.documents}
                  admin={admin}
                />
                <EmergencyDetailsButton user={{ uid: license.userId }} admin={admin} />
                <ImpersonateButton user={{ uid: license.userId }} />
                <UserEventsButton user={{ uid: license.userId }} />
                {license.type === 'approved' && (
                  <>
                    <DownloadLicenseButton license={license.approved} type="license" />
                    <DownloadLicenseButton license={license.approved} type="pitLanePass" />
                  </>
                )}
              </>,
            ]
          }),
          ids: licenses.map(({ license: { id } }) => id),
          rawData: licenses.map((row) =>
            JSON.stringify({
              tasksDone: row.tasks.tasksDone,
              tasksTotal: row.tasks.tasksTotal,
              lastName: row.license.documents.personalData?.lastName,
              firstName: row.license.documents.personalData?.firstName,
              id: row.license.id,
              all: row.all.length,
              approved: row.approved.length,
              ...(row.license.type === 'approved'
                ? {
                    issuedNumber: row.license.approved.issuedNumber,
                    licenseAssociation: row.license.approved.licenseAssociation,
                  }
                : {
                    categoryDetails: row.license.draft.categoryDetails,
                    categoryId: row.license.draft.categoryId,
                    summary: row.license.draft.summary,
                    association: row.license.association,
                    type: row.license.type,
                    userId: row.license.userId,
                  }),
            })
          ),
        }
      }
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <TextField
            label={t().licensesBoxes.search}
            variant="outlined"
            size="small"
            fullWidth
            value={search.q}
            onChange={(event) => search.set(event.currentTarget.value)}
          />
        </Box>
        <Box px={1}>
          <RoutedButton to={routes.approvedLicenses.generateTo(search.q)}>
            {routes.approvedLicenses.text()}
          </RoutedButton>
          <RoutedButton to={routes.assignLicense.generateTo(search.q)}>
            {routes.assignLicense.text()}
          </RoutedButton>
        </Box>
      </Box>
    </TableBox>
  )
}

function allowedToEditLicenseDraft(license: LicenseWithContext, adminContext: AdminContext) {
  return adminContext.matchesAssociation(license.license.draft.categoryDetails?.licenseAssociation)
}
